<template>
  <div class="container-fluid row m-0 p-0 no-gutters">
    <section class="d-flex flex-column justify-content-start m-0 py-0 px-5 col">
      <div
        class="d-flex justify-content-between align-items-end mt-4 pb-3"
        style="border-bottom: 1px solid var(--primary)"
      >
        <div class="d-flex">
          <h5 class="text-primary-light mb-0">Find your developer</h5>
          <v-icon color="#000" size="20px" class="ms-2">
            mdi-chevron-right
          </v-icon>
          <h5 v-if="project" class="text-primary-light mb-0">
            {{ project.name }}
          </h5>
          <v-icon color="#000" size="20px" class="ms-2">
            mdi-chevron-right
          </v-icon>
          <h5 v-if="property" class="text-primary-light mb-0">
            {{ property.name }} | {{ property.size }}m²
          </h5>
        </div>
      </div>

      <h2 v-if="property" class="text-neutral-dark mb-0 mt-4">
        {{ property.name }} | {{ property.size }}m²
      </h2>

      <h5 class="text-neutral-dark mt-2 font-weight-400">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lobortis
        cursus nulla quis consectetur. Vestibulum gravida posuere lectus non
        condimentum. Sed ut sem justo. Mauris fermentum ac justo vel posuere.
      </h5>
      <hr class="w-100 my-5" />

      <div class="d-flex flex-column w-100" v-if="projectId">
        <div class="w-100">
          <h1 class="text-primary mb-4">Starter Package</h1>
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <hr class="w-100 my-3" />
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3 mt-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <hr class="w-100 my-3" />
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3 mt-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/AC.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <div class="w-100 d-flex justify-content-center mt-5">
            <img
              class="wd-100p img-contain border-primary"
              style="border-radius: 0.2rem"
              :src="require('../assets/images/device-location.png')"
            />
          </div>
          <h5 class="text-neutral-dark font-weight-400 mt-3">
            Product placement is subject to change as per the client
            requirements
          </h5>
          <div class="w-100 d-flex justify-content-between mt-5">
            <h3 class="text-neutral-dark mb-0">Total EGP 3000</h3>
            <button class="rounded-button bg-primary">
              <h4 class="mb-0 text-neutral-light">Add to cart</h4>
            </button>
          </div>
        </div>
        <hr class="w-100 my-5" />
        <div class="w-100 my-4">
          <h1 class="text-primary mb-4">Starter Package</h1>
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <hr class="w-100 my-3" />
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3 mt-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <hr class="w-100 my-3" />
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3 mt-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <div class="w-100 d-flex justify-content-center mt-5">
            <img
              class="wd-100p img-contain border-primary"
              style="border-radius: 0.2rem"
              :src="require('../assets/images/device-location.png')"
            />
          </div>
          <h5 class="text-neutral-dark font-weight-400 mt-3">
            Product placement is subject to change as per the client
            requirements
          </h5>
          <div class="w-100 d-flex justify-content-between mt-5">
            <h3 class="text-neutral-dark mb-0">Total EGP 3000</h3>
            <button class="rounded-button bg-primary">
              <h4 class="mb-0 text-neutral-light">Add to cart</h4>
            </button>
          </div>
        </div>
        <hr class="w-100 my-5" />
        <div class="w-100 my-4">
          <h1 class="text-primary mb-4">Starter Package</h1>
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <hr class="w-100 my-3" />
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3 mt-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <hr class="w-100 my-3" />
          <div
            class="w-100 d-flex justify-content-start align-items-center pe-3 mt-3"
          >
            <img
              :src="require('../assets/icons/Dashboard/Lighting.png')"
              style="height: 2.5rem"
            />
            <h4 class="text-neutral-dark mb-0 mx-2">1</h4>
            <h4 class="text-neutral-dark mb-0">Switching Module</h4>
            <h4 class="text-neutral-dark mb-0 ms-auto">EGP 1000</h4>
          </div>
          <div class="w-100 d-flex justify-content-center mt-5">
            <img
              class="wd-100p img-contain border-primary"
              style="border-radius: 0.2rem"
              :src="require('../assets/images/device-location.png')"
            />
          </div>
          <h5 class="text-neutral-dark font-weight-400 mt-3">
            Product placement is subject to change as per the client
            requirements
          </h5>
          <div class="w-100 d-flex justify-content-between mt-5">
            <h3 class="text-neutral-dark mb-0">Total EGP 3000</h3>
            <button class="rounded-button bg-primary">
              <h4 class="mb-0 text-neutral-light">Add to cart</h4>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section :class="['m-0 p-0 col-3 d-none d-md-block']">
      <img
        :src="require('/src/assets/images/compound-bg.jpeg')"
        alt="tech-bg"
        class="img-height img-section"
      />
    </section>
  </div>
</template>

<script>
export default {
  name: "ProjectPropertyTypes",
  data() {
    return {
      expanded: false,
    };
  },
  mounted() {
    this.$store.dispatch("projectProperty/getProperties", {
      propertyId: this.projectId,
      developerId: this.developerId,
    });
  },
  computed: {
    developerId() {
      return this.$route.params.developerId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    propertyId() {
      return this.$route.params.projectId;
    },
    property() {
      return this.$store.state.projectProperty.properties.find(
        (p) => p.id === this.propertyId
      );
    },
    project() {
      return this.$store.state.developerProject.projects.find(
        (p) => p.id === this.projectId
      );
    },
    properties() {
      return this.$store.state.projectProperty.properties;
    },
  },
};
</script>

<style scoped lang="scss">
.img-height {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  object-fit: cover;
}

.img-section {
  animation: backgroundScroll ease-in-out 35s infinite;
}

@keyframes backgroundScroll {
  0% {
    object-position: 0;
  }
  50% {
    object-position: 100%;
  }

  100% {
    object-position: 0;
  }
}
</style>
